<script setup lang="ts">
import { changeLocale } from '@formkit/vue'
import EarthIcon from '~/assets/icons/environment/earth.svg'

defineProps<{
  component?: 'select' | 'dropdown' | undefined
}>()

const { locales, setLocale, locale, setLocaleCookie } = useI18n()
const isSwitching = ref(false)

const langs = computed(() => {
  return locales.value.map((lang) => {
    if (typeof lang === 'string') {
      return {
        label: lang,
        key: lang,
        value: lang,
      }
    }
    return {
      label: lang?.name,
      key: lang?.code,
      value: lang?.code,
    }
  })
})

onMounted(async () => {
  // Set the correct FormKit language on mount
  changeLocale(locale.value)
})

const handleSelect = async (newLocale: string) => {
  isSwitching.value = true
  await setLocale(newLocale)
  setLocaleCookie(newLocale)
  changeLocale(newLocale)
  isSwitching.value = false
}
</script>

<template>
  <div>
    <n-dropdown v-if="component === 'dropdown'" :disabled="isSwitching" :options="langs" placement="bottom-end" @select="handleSelect">
      <n-button :loading="isSwitching" class="p-2" secondary>
        <template #icon>
          <EarthIcon />
        </template>
      </n-button>
    </n-dropdown>
    <div v-else>
      <n-select v-model:value="locale" :disabled="isSwitching" :options="langs" @update:value="handleSelect" />
    </div>
  </div>
</template>
