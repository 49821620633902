<script lang="ts" setup>
import CityLogoTextDE from '~/assets/icons/logo/text/de.svg'
import CityLogoTextEN from '~/assets/icons/logo/text/en.svg'
import CityLogoIcon from '~/assets/icons/logo/icon.svg'

withDefaults(defineProps<{
  maxWidth?: string
  showLogo?: boolean
  showIcon?: boolean
  showText?: boolean
  textFontSize?: string
}>(), {
  maxWidth: '300px',
  showLogo: true,
  showIcon: false,
  showText: true,
  textFontSize: '16px',
})

const { locale } = useI18n()
</script>

<template>
  <div
    class="flex items-center flex-col gap-5 select-none w-full"
  >
    <div class="shrink-0 w-full flex justify-center items-center grow">
      <CityLogoIcon
        v-if="showIcon"
        class="block overflow-visible"
        :style="{ height: '100%', width: '100%', maxWidth }"
      />
      <template v-else-if="showLogo">
        <CityLogoTextDE
          v-if="locale === 'de'"
          class="block overflow-visible px-2"
          :style="{ height: '100%', width: '100%', maxWidth }"
        />
        <CityLogoTextEN
          v-else
          class="block overflow-visible px-2 py-1.5"
          :style="{ height: '100%', width: '100%', maxWidth }"
        />
      </template>
    </div>
    <div v-if="showText" class="w-full flex flex-col items-center py-2 mb-1.5 bg-gray-100 border-y">
      <p class="whitespace-nowrap text-black tracking-wide font-semibold" :style="{ fontSize: textFontSize }">
        {{ $t('app.title') }}
      </p>
    </div>
  </div>
</template>
