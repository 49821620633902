import { joinURL } from 'ufo'
import type { MenuOption } from 'naive-ui'
import { canAccess } from '~/authorization/permissions'

const _findMatchingOption = (options: MenuOption[], key: string, prefixMatch = false) => options.find((option) => {
  const optionKey = option.key as string | undefined
  if (!optionKey) {
    return false
  }

  if (prefixMatch) {
    if (optionKey === '/') {
      return optionKey === key
    }
    return key.startsWith(optionKey)
  }

  return optionKey === key
})

export default () => {
  const { role } = useAuthorization()
  const { menuItemsConfig, footerMenuItemsConfig } = useMenuConfig()

  const makeMenuOptionFromItemConfig = (path: string, config: MenuItemConfig): MenuOption => {
    let children: MenuOption[] | undefined
    const childrenToRender = config.children ? Object.entries(config.children).filter(([_, childConfig]) => childConfig.doRender) : []
    if (childrenToRender.length > 0) {
      children = childrenToRender.map(
        ([childPath, childConfig]) => makeMenuOptionFromItemConfig(joinURL(path, childPath), childConfig),
      )
    }

    const show = canAccess(role.value, path)

    const icon = () => {
      if (!config.icon) {
        return undefined
      }
      if (typeof config.icon === 'string') {
        return useRenderIcon({ name: config.icon })
      }
      return h(config.icon)
    }

    return {
      label: config.isRouterLink ? useRouterLink(config.label, path, config.routerOptions) : config.label,
      labelText: config.label,
      key: path,
      icon,
      children,
      show,
    }
  }

  const menuOptions = computed((): MenuOption[] => Object.entries(menuItemsConfig.value)
    .filter(([_, config]) => config.doRender)
    .map(([path, config]) => {
      const menuOption = makeMenuOptionFromItemConfig(path, config)
      const options = []

      if (config.putDividerAbove) {
        options.push({
          type: 'divider',
          key: `${path}-divider-above`,
          show: menuOption.show,
        })
      }

      options.push(menuOption)

      if (config.putDividerBelow) {
        options.push({
          type: 'divider',
          key: `${path}-divider-below`,
          show: menuOption.show,
        })
      }

      return options
    })
    .flat(),
  )

  const footerOptions = computed((): MenuOption[] => Object.entries(footerMenuItemsConfig.value)
    .filter(([_, config]) => config.doRender)
    .map(([path, config]) => {
      const menuOption = makeMenuOptionFromItemConfig(path, config)
      const options = []

      if (config.putDividerAbove) {
        options.push({
          type: 'divider',
          key: `${path}-divider-above`,
          show: menuOption.show,
        })
      }

      options.push(menuOption)

      if (config.putDividerBelow) {
        options.push({
          type: 'divider',
          key: `${path}-divider-below`,
          show: menuOption.show,
        })
      }

      return options
    })
    .flat(),
  )

  const findMatchingOption = (key: string, prefixMatch = false, options = menuOptions.value) => _findMatchingOption(options, key, prefixMatch)

  return { menuOptions, footerOptions, findMatchingOption }
}
